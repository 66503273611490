const FooterData = [
    {
        title: "Paywistech Africa",
        submenu: [
            {
                title: "About company",
                link: "#"
            },
            {
                title: "Company services",
                link: "/page/our-services"
            },
            {
                title: "Job opportunities",
                link: "#"
            },
            {
                title: "Contact us",
                link: "#"
            },
        ]
    },
    {
        title: "Our Identity",
        submenu: [
            {
                title: "Client support",
                link: "#"
            },
            {
                title: "Pricing packages",
                link: "#"
            },
            {
                title: "Company history",
                link: "#"
            },
            {
                title: "Our process",
                link: "#"
            },
        ]
    },
    // {
    //     title: "Additional",
    //     submenu: [
    //         {
    //             title: "Our story",
    //             link: "/page/our-story"
    //         },
    //         {
    //             title: "Who we are",
    //             link: "/page/who-we-are"
    //         },
    //         {
    //             title: "Our process",
    //             link: "/page/our-process"
    //         },
    //         {
    //             title: "Latest news",
    //             link: "/page/latest-news"
    //         },
    //     ]
    // },
    {
        title: "Services",
        submenu: [
            {

                title: "flexible Payment",
                link: "#"
            },
            {
                title: "Phone financing",
                link: "#"
            },
            {
                title: "Financing",
                link: "#"
            },
            // {
            //     title: "Marketing strategy",
            //     link: "#"
            // },
        ]
    },
    // {
    //     title: "Resources",
    //     submenu: [
    //         {
    //             title: "Theme guide",
    //             link: "/page/faq-s"
    //         },
    //         {
    //             title: "Support desk",
    //             link: "/page/faq-s"
    //         },
    //         {
    //             title: "What we offer",
    //             link: "/page/what-we-offer"
    //         },
    //         {
    //             title: "Company history",
    //             link: "/page/our-story"
    //         },
    //     ]
    // },
    // {
    //     title: "Categories",
    //     submenu: [
    //         {
    //             title: "For men",
    //             link: "/shop/shop-wide"
    //         },
    //         {
    //             title: "For woman",
    //             link: "/shop/shop-wide"
    //         },
    //         {
    //             title: "Accessories",
    //             link: "/shop/shop-wide"
    //         },
    //         {
    //             title: "Collections",
    //             link: "/shop/shop-wide"
    //         },
    //     ]
    // },
    // {
    //     title: "Adavantages",
    //     submenu: [
    //         {
    //             title: "Free delivery",
    //             link: "/page/faq-s"
    //         },
    //         {
    //             title: "100 days refund",
    //             link: "/page/faq-s"
    //         },
    //         {
    //             title: "Multiple payments",
    //             link: "/page/faq-s"
    //         },
    //         {
    //             title: "Sustainable",
    //             link: "/page/faq-s"
    //         },
    //     ]
    // },
]

export default FooterData